const BASE_URL = process.env.REACT_APP_URL_API;

const fetchCategoryProjectList = async ({ queryKey }) => {
  const lang = queryKey[1];
  const page = queryKey[2];
  const country=queryKey[3];
  if (!lang || !page) return [];
  const apiRest = await fetch(
    `${BASE_URL}/planification/categorie?lang=${lang}&page=${page}`,
      {
        headers: {'X-country':country.toLowerCase(),
        }
      }
  );
  return apiRest.json();
};
const postCategoryProject = async (data) => {
  console.log({plancat:data})
  const apiRest = await fetch(`${BASE_URL}/planification/categorie`, {
    method: "POST",
    body: data?.form,
  headers:{
      'X-country':data?.country.toLowerCase()
  }
  });
  return apiRest.json();
};
const updateCategoryProject = async ({ data, id,country }) => {
  const apiRest = await fetch(`${BASE_URL}/planification/categorie?id=${id}`, {
    method: "PATCH",
    body: data,
    headers:{
      'X-country':country.toLowerCase()
    }
  });
  return apiRest.json();
};
const deleteCategoryProject = async ({id,country}) => {
  const apiRest = await fetch(`${BASE_URL}/planification/categorie?id=${id}`, {
    method: "DELETE",
    body: {},
    headers:{
      'X-country':country.toLowerCase()
    }
  });
  return apiRest.json();
};
const fetchCategoryProjectListNoPage = async ({ queryKey }) => {
  const lang = queryKey[1];
  const country=queryKey[2];
  console.log({country})

  const apiRest = await fetch(
    `${BASE_URL}/planification/categorie-all?lang=${lang}`,
      {
        headers: country.toLowerCase()
      }
  );
  return apiRest.json();
};

const fetchProjetsList = async ({ queryKey }) => {
  const lang = queryKey[1];
  const page = queryKey[2];
  const country= queryKey[3]
  if (!lang || !page) return [];
  const apiRest = await fetch(
    `${BASE_URL}/planification/projets?lang=${lang}&page=${page}`,
      {
        headers:{
          'X-country':country.toLowerCase()
        }
      }
  );
  return apiRest.json();
};

const fetchChampsFixeList = async ({ queryKey }) => {
  const [_, lang, page,country] = queryKey;
  if (!lang || !page) return [];
  const apiRest = await fetch(
    `${BASE_URL}/planification/field-fixe?lang=${lang}&page=${page}`,
      {
        headers:{
          'X-country':country.toLowerCase()
        }
      }
  );
  return apiRest.json();
};

const fetchProjetDetail = async ({ queryKey }) => {
  const { lang, id } = queryKey[1];
  const country=queryKey[2];
  if (!id) return [];
  const apiRest = await fetch(
    `${BASE_URL}/planification/projet-detail?projetId=${id}&lang=${lang}`,
      {
        headers:{
          'X-country':country.toLowerCase()
        }
      }
  );
  return apiRest.json();
};

const fetchCultureList = async ({ queryKey }) => {
  const lang = queryKey[1];
  const page = queryKey[2];
  const country=queryKey[3];
  if (!lang || !page) return [];
  const apiRest = await fetch(
    `${BASE_URL}/planification/culture?lang=${lang}&page=${page}`,
      {
        headers:{
          'X-country':country.toLowerCase()
        }
      }
  );
  return apiRest.json();
};
const postCulture = async (data) => {
  console.log({data})
  const apiRest = await fetch(`${BASE_URL}/planification/culture`, {
    method: "POST",
    body: data?.form,
  headers:{
      'X-country':data?.country.toLowerCase()
  }
  });
  return apiRest.json();
};
const updateCulture = async ({ data, id, country }) => {
  const apiRest = await fetch(`${BASE_URL}/planification/culture?id=${id}`, {
    method: "PATCH",
    body: data,
    headers:country?.toLowerCase()

  });
  return apiRest.json();
};

const deleteCulture = async ({id,country}) => {
  const apiRest = await fetch(`${BASE_URL}/planification/culture?id=${id}`, {
    method: "DELETE",
    body: {},
    headers:{
      'X-country':country?.toLowerCase()
    }
  });
  return apiRest.json();
};

const postChampFixe = async (data) => {
  const apiRest = await fetch(`${BASE_URL}/planification/field-fixe`, {
    method: "POST",
    body: data?.form,
    headers:{
      'X-country':data?.country.toLowerCase()
    }
  });
  return apiRest.json();
};
const updateChampFixe = async ({ data, id,country }) => {
  const apiRest = await fetch(`${BASE_URL}/planification/field-fixe?id=${id}`, {
    method: "PATCH",
    body: data,
    headers:{
      'X-country':country.toLowerCase()
    }
  });
  return apiRest.json();
};
const deleteChampFixe = async ({id,country}) => {
  const apiRest = await fetch(`${BASE_URL}/planification/field-fixe?id=${id}`, {
    method: "DELETE",
    body: {},
    headers:{
      'X-country':country.toLowerCase()
    }
  });
  return apiRest.json();
};

const fetchChampsVariableList = async ({ queryKey }) => {
  const [_, lang, page,country] = queryKey;
  if (!lang || !page) return [];
  const apiRest = await fetch(
    `${BASE_URL}/planification/field-variable?lang=${lang}&page=${page}`,
      {
        headers:{
          'X-country':country.toLowerCase()
        }
      }
  );
  return apiRest.json();
};
const postChampVariable = async (data) => {
  const apiRest = await fetch(`${BASE_URL}/planification/field-variable`, {
    method: "POST",
    body: data?.form,
    headers:{
      'X-country':data?.country.toLowerCase()
    }
  });
  return apiRest.json();
};
const updateChampVariable = async ({ data, id,country }) => {
  const apiRest = await fetch(
    `${BASE_URL}/planification/field-variable?id=${id}`,
    {
      method: "PATCH",
      body: data,
      headers:{
        'X-country':country.toLowerCase()
      }
    }
  );
  return apiRest.json();
};
const deleteChampVariable = async ({id,country}) => {
  const apiRest = await fetch(
    `${BASE_URL}/planification/field-variable?id=${id}`,
    {
      method: "DELETE",
      body: {},
      headers:{
        'X-country':country.toLowerCase()
      }
    }
  );
  return apiRest.json();
};
const fetchAllFieldsAndCultureNoPage = async ({ queryKey }) => {
  const lang = queryKey[1];
  const country=queryKey[2];
  if (!lang) return [];
  const apiRest = await fetch(
    `${BASE_URL}/planification/all-fields-and-cultures?lang=${lang}`,
      {
        headers:{
          'X-country':country.toLowerCase()
        }
      }
  );
  return apiRest.json();
};
export {
  fetchProjetsList,
  fetchCategoryProjectList,
  fetchCultureList,
  fetchChampsFixeList,
  fetchProjetDetail,
  postCulture,
  updateCulture,
  deleteCulture,
  postChampFixe,
  updateChampFixe,
  deleteChampFixe,
  postCategoryProject,
  updateCategoryProject,
  deleteCategoryProject,
  fetchCategoryProjectListNoPage,
  fetchChampsVariableList,
  postChampVariable,
  updateChampVariable,
  deleteChampVariable,
  fetchAllFieldsAndCultureNoPage,
};
