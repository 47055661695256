import {useSelector} from "react-redux";
import {useQueryStatiqueAddressData} from "./queriesStatiqueData";

const { useState, useEffect } = require("react");

const useGetProvinces = (query) => {
  const [provinces, setProvinces] = useState([]);
  const {data,isFetching} = query;

      useEffect(()=>{
        if(isFetching===false){
          const province = data?.map((item) => {
            return {
              value: item.area1,
              label: item.area1,
            };
          });
          setProvinces(province);
        }

      },[isFetching])


      return provinces


};

const useGetCommunes = (query, formik) => {
  const [communes, setCommunes] = useState([]);
  const [loaded,setLoaded]=useState(true);

    const {data, isFetching} = query;


  useEffect(() => {

    if(isFetching===false && (formik.values["provinceId"] ||formik.values["provinceId"]!=='')){
     const newCommunes = data?.map((item) => {
        return {
          value: item.area2,
          label: item.area2,
        };
      });
      if (formik.dirty) {
        formik.values["communeId"] = "";
        formik.values["zoneId"] = "";
        formik.values["collineId"] = "";
        formik.values["localiteId"] = "";
      }
      setCommunes(newCommunes);
    }

  }, [formik.values["provinceId"],isFetching]);

  return communes;
};

const useGetZones = (query, formik) => {
  const [zones, setZones] = useState([]);
  const {data,isFetching} = query;
  useEffect(() => {

    if(isFetching===false && formik.values["communeId"]!==''){
      const newZones = data?.map((item) => {
        return {
          value: item.area3,
          label: item.area3,
        };
      });
      if (formik.dirty) {

        formik.values["zoneId"] = "";
        formik.values["collineId"] = "";
        formik.values["localiteId"] = "";
      }
      setZones(newZones);
    }

  }, [formik.values["communeId"],isFetching]);

  return zones;
};

const useGetCollines = (query, formik) => {
  const [collines, setCollines] = useState([]);
  const {data,isFetching} = query;
  useEffect(() => {

    if (isFetching === false && formik.values["zoneId"] !== '') {
      const newCollines = data?.map((item) => {
        return {
          value: item.area4,
          label: item.area4,
        };
      });
      if (formik.dirty) {


        formik.values["collineId"] = "";
        formik.values["localiteId"] = "";
      }
      setCollines(newCollines);
    }

  },[formik.values["zoneId"],isFetching])
  return collines
}

const useGetLocalites = (query, formik) => {
  const [localites, setLocalites] = useState([]);
  const {data,isFetching} = query;
  useEffect(() => {

    if (isFetching === false && formik.values["collineId"] !== '') {
      const newLocalities = data?.map((item) => {
        return {
          value: item.area5,
          label: item.area5,
        };
      });
      if (formik.dirty) {



        formik.values["localiteId"] = "";
      }
      setLocalites(newLocalities);
    }

  },[formik.values["collineId"],isFetching])
  return localites
};
export {
  useGetProvinces,
  useGetCommunes,
  useGetZones,
  useGetCollines,
  useGetLocalites,
};
