import { useQuery } from "@tanstack/react-query";
import { fetchRapport } from "services/rapportsApi";
import {useSelector} from "react-redux";
const useQueryRapport = (params) => {
  const {dataUser}=useSelector((store)=>store.user)
  const { data, isPending, refetch, isFetching } = useQuery({
    queryKey: ["rapport", params,dataUser?.address],
    queryFn: fetchRapport,
    enabled: false,
  });
  return [data?.data ?? [], isFetching, refetch];
};

export { useQueryRapport };
