import { useQuery } from "@tanstack/react-query";
import { fetchDashboardStatisque } from "services/dashboardApi";
import {useSelector} from "react-redux";

const useQuerieDashboardStat = (lang) => {
  const {dataUser}=  useSelector((store) => store.user);
  console.log({dataUser})
  const { data, isPending } = useQuery({
    queryKey: ["dashboard-static", lang,dataUser.address],
    queryFn: fetchDashboardStatisque,
  });

  return [data?.data ?? [], isPending];
};
export { useQuerieDashboardStat };
