import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const useGetCurrentMenu = (menuData) => {
  const { pathname } = useLocation();
  const [value, setValue] = useState("");
  useEffect(() => {
    const valMenu = menuData.filter((item) => pathname.startsWith(item.name));
    if (valMenu.length > 0) {
      setValue(valMenu[0].key);
    }
  }, [pathname]);
  return value.toString();
};
export { useGetCurrentMenu };
