import {useSelector} from "react-redux";
import {store} from "../redux/store";

const BASE_URL = process.env.REACT_APP_URL_API;
const fetchCategoriesFournisseurs = async ({ queryKey }) => {
  const [_, lang, page,country] = queryKey;
  if (!lang || !page) return [];
  const apiRest = await fetch(
    `${BASE_URL}/service/categorie?lang=${lang}&page=${page}`,
      {
        headers: {
          'X-country':country.toLowerCase(),
        }
      }
  );
  return apiRest.json();
};
const fetchCategoriesFournisseursNoPage = async ({ queryKey }) => {
  const lang = queryKey[1];
  const country=queryKey[2];

  if (!lang) return [];
  const apiRest = await fetch(`${BASE_URL}/service/categorie-all?lang=${lang}`,
      {
        headers: {
          'X-country': country.toLowerCase(),
        }
      });
  return apiRest.json();
};
const postCategoriesFournisseur = async (data) => {
  const apiRest = await fetch(`${BASE_URL}/service/categorie`, {
    method: "POST",
    body: data?.form,
    headers: {
      'X-country':data?.country.toLowerCase(),
    }
  });
  return apiRest.json();
};
const updateCategorieFournisseur = async ({ data, id,country }) => {
  const apiRest = await fetch(`${BASE_URL}/service/categorie?id=${id}`, {
    method: "PATCH",
    body: data,
    headers: {
      'X-country':country.toLowerCase(),
    }
  });
  return apiRest.json();
};
const deleteCategoreFournisseur = async ({id,country}) => {
  const apiRest = await fetch(`${BASE_URL}/service/categorie?id=${id}`, {
    method: "DELETE",
    body: {},
    headers: {
      'X-country':country.toLowerCase(),
    }
  });
  return apiRest.json();
};

const fetchFournisseurs = async ({ queryKey }) => {
  const [_, lang, page,country] = queryKey;
  console.log({country})
  if (!lang || !page) return [];
  const apiRest = await fetch(
    `${BASE_URL}/service/fournisseur?lang=${lang}&page=${page}`,
      {
        headers: {
          'X-country':country?.toLowerCase(),
        }
      }
  );
  return apiRest.json();
};

const postFournisseur = async (data) => {
  const apiRest = await fetch(`${BASE_URL}/service/fournisseur`, {
    method: "POST",
    body: data?.form,
    headers: {
      'X-country':data?.country.toLowerCase(),
    }
  });
  return apiRest.json();
};

const updateFournisseur = async ({ data, id,country }) => {
  const apiRest = await fetch(`${BASE_URL}/service/fournisseur?id=${id}`, {
    method: "PATCH",
    body: data,
    headers: {
       'X-country':country?.toLowerCase(),
    }
  });
  return apiRest.json();
};
const deleteFournisseur = async (id) => {
  const apiRest = await fetch(`${BASE_URL}/service/fournisseur?id=${id}`, {
    method: "DELETE",
    body: {},
    headers: {
      // 'X-country':'bf',
    }
  });
  return apiRest.json();
};

const fetchOneFournisseurWithServices = async ({ queryKey }) => {
  const { fId, lang, page } = queryKey[1];
const country=queryKey[2]
  console.log({dataCountry:country})
  if (!lang || !page || !fId) return [];
  const apiRest = await fetch(
    `${BASE_URL}/service/fournisseur-service?lang=${lang}&page=${page}&fId=${fId}`,
      {
        headers: {
          'X-country':country.toLowerCase(),
        }
      }
  );
  return apiRest.json();
};
const postServiceFournisseur = async (data) => {
  const apiRest = await fetch(`${BASE_URL}/service`, {
    method: "POST",
    body: data?.form,
    headers: {
      'X-country':data?.country.toLowerCase(),
    }
  });
  return apiRest.json();
};
const updateServiceFournisseur = async ({ data, id,country }) => {
  const apiRest = await fetch(`${BASE_URL}/service?id=${id}`, {
    method: "PATCH",
    body: data,
    headers: {
      'X-country':country.toLowerCase(),
    }
  });
  return apiRest.json();
};

const deleteServiceFournisseur = async ({id,country}) => {
  const apiRest = await fetch(`${BASE_URL}/service?id=${id}`, {
    method: "DELETE",
    body: {},
    headers: {
      'X-country':country.toLowerCase(),
    }
  });
  return apiRest.json();
};
export {
  fetchFournisseurs,
  fetchCategoriesFournisseurs,
  postCategoriesFournisseur,
  updateCategorieFournisseur,
  deleteCategoreFournisseur,
  fetchCategoriesFournisseursNoPage,
  postFournisseur,
  updateFournisseur,
  deleteFournisseur,
  fetchOneFournisseurWithServices,
  postServiceFournisseur,
  updateServiceFournisseur,
  deleteServiceFournisseur,
};
