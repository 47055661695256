const BASE_URL = process.env.REACT_APP_URL_API;
const fetchAllStatiqueAddressData = async ({queryKey}) => {
const country=queryKey[2];
const params=queryKey[1];
console.log({country,params})
  const url =new URL(`${BASE_URL}/statique/address`);
const search= new URLSearchParams(params).toString();
console.log({search})
  url.search = new URLSearchParams(params).toString();
  console.log({url});

  const apiRest = await fetch(url,{
      params,
    headers: {
      'X-country':country.toLowerCase(),
    }
  });

  return apiRest.json();
};

export { fetchAllStatiqueAddressData };
