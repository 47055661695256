import { useQuery } from "@tanstack/react-query";
import {
  fetchAcademieListCategory,
  fetchAcademieOneCategory,
  fetchAcademieQuestionCategory,
} from "services/academieApi";
import {useSelector} from "react-redux";
const useQuerieAcademieCategoriesList = (lang, page) => {
  const {dataUser}=  useSelector((store) => store.user);
  const { data, isPending, isFetching } = useQuery({
    queryKey: ["academie-list-categories", lang, page,dataUser.address],
    queryFn: fetchAcademieListCategory,
  });
  return [
    { data: data?.data.rows ?? [], dataCount: data?.data.count ?? 0 },
    isFetching,
  ];
};

const useQuerieAcademieOneCategory = (params) => {
  const {dataUser}=useSelector((store)=>store.user);
  const { data, isPending, isFetching } = useQuery({
    queryKey: ["category-with-videos", params,dataUser?.address],
    queryFn: fetchAcademieOneCategory,
  });
  return [data?.data ?? {}, isFetching];
};

const useQuerieAcademieCategoryQuestions = (params) => {
  const { data, isPending, isFetching } = useQuery({
    queryKey: ["category-with-questions", params],
    queryFn: fetchAcademieQuestionCategory,
  });

  return [data?.data ?? {}, isFetching];
};

export {
  useQuerieAcademieCategoriesList,
  useQuerieAcademieOneCategory,
  useQuerieAcademieCategoryQuestions,
};
