import { useQuery } from "@tanstack/react-query";
import { fetchOneUser, fetchUserList } from "services/usersApi";
const useQuerieUsers = (page) => {
  const { data, isPending, isFetching } = useQuery({
    queryKey: ["users-list", page],
    queryFn: fetchUserList,
  });
  return [
    { data: data?.data.rows ?? [], dataCount: data?.data.count ?? 0 },
    isFetching,
  ];
};

const useQuerieOneUser = (params) => {
  const { data, isPending, isFetching } = useQuery({
    queryKey: ["one-user", params],
    queryFn: fetchOneUser,
  });
  return [data?.data ?? {}, isFetching];
};
export { useQuerieUsers, useQuerieOneUser };
