const BASE_URL = process.env.REACT_APP_URL_API;
const postUser = async (data) => {
  const apiRest = await fetch(`${BASE_URL}/users`, {
    method: "POST",
    body: data,
    headers: {
      'X-country':'bf',
    }
  });
  return apiRest.json();
};
const fetchUserList = async ({ queryKey }) => {
  const page = queryKey[1];
  if (!page) return [];
  const apiRest = await fetch(`${BASE_URL}/users?page=${page}`,
      {
        headers: {
          'X-country':'bf',
        }
      });
  return apiRest.json();
};
const changeUserPassword = async (data) => {
  const apiRest = await fetch(`${BASE_URL}/users/change-password`, {
    method: "PATCH",
    body: data,
    headers: {
      'X-country':'bf',
    }
  });
  return apiRest.json();
};
const blocUserAccount = async (id) => {
  const apiRest = await fetch(`${BASE_URL}/users/state-account?id=${id}`, {
    method: "PATCH",
    body: {},
    headers: {
      'X-country':'bf',
    }
  });
  return apiRest.json();
};
const fetchOneUser = async ({ queryKey }) => {
  const id = queryKey[1];

  if (!id) return [];
  const apiRest = await fetch(`${BASE_URL}/users/detail?id=${id}`);
  return apiRest.json();
};
const updateUser = async (data) => {
  const apiRest = await fetch(`${BASE_URL}/users`, {
    method: "PATCH",
    body: data,
    headers: {
      'X-country':'bf',
    }
  });
  return apiRest.json();
};
export {
  postUser,
  fetchUserList,
  fetchOneUser,
  changeUserPassword,
  blocUserAccount,
  updateUser,
};
