import { useQuery } from "@tanstack/react-query";
import { fetchAllStatiqueAddressData } from "services/statiqueDataApi";
import {useSelector} from "react-redux";
const useQueryStatiqueAddressData = (params) => {
  console.log({params})
  const {dataUser}=useSelector((store)=>store.user)
  const { data, isPending, isFetching } = useQuery({

    queryKey: ["statique-address-data", params,dataUser?.address],
    queryFn: fetchAllStatiqueAddressData,
  });

  return {data:data?.data ?? {}, isFetching};
};

export { useQueryStatiqueAddressData };
