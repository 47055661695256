import { useQuery } from "@tanstack/react-query";
import { fetchUsageVideos, fetchOneUsageVideo } from "services/usageApi";
import {useSelector} from "react-redux";

const useQuerieUsageVideos = (lang, page) => {
  const {dataUser}=useSelector((store)=>store.user)
  const { data, isPending, isFetching } = useQuery({
    queryKey: ["usages-videos", lang, page,dataUser?.address],
    queryFn: fetchUsageVideos,
  });
  return [
    { data: data?.data.rows ?? [], dataCount: data?.data.count ?? 0 },
    isFetching,
  ];
};
const useQuerieUsageOneVideo = (params) => {
  const {dataUser}=useSelector((store)=>store.user)
  const { data, isPending, isFetching } = useQuery({
    queryKey: ["usage-one-video", params,dataUser?.address],
    queryFn: fetchOneUsageVideo,
  });
  return [data?.data ?? [], isFetching];
};
export { useQuerieUsageVideos, useQuerieUsageOneVideo };
