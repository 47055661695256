import { useQuery } from "@tanstack/react-query";
import {
  fetchFournisseurs,
  fetchCategoriesFournisseurs,
  fetchCategoriesFournisseursNoPage,
  fetchOneFournisseurWithServices,
} from "services/serviceApi";
import {useSelector} from "react-redux";

const useQuerieCategorieFournisseur = (lang, page) => {
  const {dataUser}=useSelector((store)=>store.user)
  const { data, isPending, isFetching } = useQuery({
    queryKey: ["categorie-fournisseurs", lang, page,dataUser?.address],
    queryFn: fetchCategoriesFournisseurs,
  });
  return [
    { data: data?.data.rows ?? [], dataCount: data?.data.count ?? 0 },
    isFetching,
  ];
};
const useQueryCategorieFournisseurNoPage = (lang) => {
  const {dataUser}=useSelector((store)=>store.user)
  console.log({dataUser})
  const { data, isPending, isFetching } = useQuery({
    queryKey: ["all-categorie-fournisseurs", lang,dataUser?.address],
    queryFn: fetchCategoriesFournisseursNoPage,
  });
  return [data?.data ?? [], isFetching];
};

const useQuerieFournisseur = (lang, page) => {
  const {dataUser}=useSelector((store)=>store.user)
  const { data, isPending, isFetching } = useQuery({
    queryKey: ["fournisseurs", lang, page,dataUser?.address],
    queryFn: fetchFournisseurs,
  });
  return [
    { data: data?.data.rows ?? [], dataCount: data?.data.count ?? 0 },
    isFetching,
  ];
};

const useQueryFournisseurWithServices = (params) => {
  const {dataUser}=useSelector((store)=>store.user)
  console.log({dataUser})
  const { data, isPending, isFetching } = useQuery({
    queryKey: ["fournisseurs-with-services", params,dataUser?.address],
    queryFn: fetchOneFournisseurWithServices,
  });
  return [data?.data ?? [], isFetching];
};
export {
  useQuerieFournisseur,
  useQuerieCategorieFournisseur,
  useQueryCategorieFournisseurNoPage,
  useQueryFournisseurWithServices,
};
