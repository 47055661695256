import { useQuery } from "@tanstack/react-query";
import {
  fetchCategoryProjectListNoPage,
  fetchAllFieldsAndCultureNoPage,
} from "services/planificationApi";
import {useSelector} from "react-redux";
const useQueryAllCategoryProjet = (params) => {
  const {dataUser}=useSelector((store)=>store.user)
  const { data, isPending, isFetching } = useQuery({
    queryKey: ["all-categorie-no-page", params,dataUser?.address],
    queryFn: fetchCategoryProjectListNoPage,
  });

  return [data?.data ?? [], isFetching];
};
const useQueryAllFieldsAndCultures = (lang) => {
  const {dataUser}=useSelector((store)=>store.user)
  const { data, isFetching } = useQuery({
    queryKey: ["fields-and-cultures", lang,dataUser?.address],
    queryFn: fetchAllFieldsAndCultureNoPage,
  });
  return [data?.data ?? { fields: [], varietes: [] }, isFetching];
};

export { useQueryAllCategoryProjet, useQueryAllFieldsAndCultures };
