import { formatterDateBeginWithYear } from "helpers";
const BASE_URL = process.env.REACT_APP_URL_API;
const NAME_ENDPOINT = [
  "none",
  "install",
  "connexion",
  "sync",
  "vue-video",
  "reponse-questions",
  "projet-sync",
  "commandes",
];
const fetchRapport = async ({ queryKey }) => {
  const { dateDebut, dateFin, typeRapportId, lang } = queryKey[1];
  const country=queryKey[2]
  const newDateDebut = dateDebut ? formatterDateBeginWithYear(dateDebut) : "";
  const newDateFin = dateFin ? formatterDateBeginWithYear(dateFin) : "";
  if (!lang || !dateDebut) return [];
  const apiRest = await fetch(
    `${BASE_URL}/rapport/${NAME_ENDPOINT[typeRapportId]}?lang=${lang}&dateDebut=${newDateDebut}&dateFin=${newDateFin}`,
      {
        headers: {
          'X-country':country.toLowerCase(),
        }
      }
  );
  return apiRest.json();
};

export { fetchRapport };
