const BASE_URL = process.env.REACT_APP_URL_API;
const fetchUsageVideos = async ({ queryKey }) => {
  const lang = queryKey[1];
  const page = queryKey[2];
  const country=queryKey[3];
  if (!lang || !page) return [];
  const apiRest = await fetch(
    `${BASE_URL}/usage/video?lang=${lang}&page=${page}`,
      {
        headers: {
          'X-country':country.toLowerCase(),
        }
      }
  );
  return apiRest.json();
};
const fetchOneUsageVideo = async ({ queryKey }) => {
  const { id, lang } = queryKey[1];
  const country=queryKey[2];
  if (!lang || !id) return [];
  const apiRest = await fetch(
    `${BASE_URL}/usage/get-one-video-usage?lang=${lang}&id=${id}`,
      {
        headers: {
          'X-country':country?.toLowerCase(),
        }
      }
  );
  return apiRest.json();
};

const postUsageVideo = async (data) => {

  const apiRest = await fetch(`${BASE_URL}/usage/video`, {
    method: "POST",
    body: data?.form,
    headers: {
      'X-country':data?.country.toLowerCase(),
    }
  });
  return apiRest.json();
};

const updateUsageVideo = async ({ data, id,country }) => {
  const apiRest = await fetch(`${BASE_URL}/usage/video?id=${id}`, {
    method: "PATCH",
    body: data,
    headers: {
      'X-country':country.toLowerCase(),
    }
  });
  return apiRest.json();
};

const deleteUsageVideo = async ({id,country}) => {
  const apiRest = await fetch(`${BASE_URL}/usage/video?id=${id}`, {
    method: "DELETE",
    body: {},
    headers: {
      'X-country':country.toLowerCase(),
    }
  });

  return apiRest.json();
};

export {
  fetchUsageVideos,
  postUsageVideo,
  fetchOneUsageVideo,
  deleteUsageVideo,
  updateUsageVideo,
};
