const BASE_URL = process.env.REACT_APP_URL_API;
const fetchDashboardStatisque = async ({ queryKey }) => {

  const lang = queryKey[1];
  const country=queryKey[2];
console.log({country})
  if (!lang) return [];
  const apiRest = await fetch(`${BASE_URL}/dashboard?lang=${lang}`,{
    headers: {
      'X-country':country?.toLowerCase(),
    }
  });
  return apiRest.json();
};

export { fetchDashboardStatisque };
